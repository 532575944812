import React, { ReactNode } from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import styled from '@emotion/styled';
import { sanitize } from 'dompurify';
import { Placement } from 'react-bootstrap/esm/Overlay';

interface ModulePopoverProps {
  body: string;
  children?: ReactNode;
  placement: Placement;
  rootClose?: boolean;
  title: string;
  trigger?: 'click' | 'hover' | 'focus';
}

const PopoverHeading = styled.h3`
  background-color: #323841;
  border-bottom: 1px solid #ebebeb;
  border-radius: 2px 2px 0 0;
  color: #FFFFFF;
  font-size: 1rem;
  margin: 0;
  padding: 8px 14px;
`;

const createMarkupHelpText = (html: string) => ({
  __html: sanitize(html, {
    FORBID_ATTR: [ `style` ],
    FORBID_TAGS: [ `img`, `style` ],
    USE_PROFILES: { html: true },
  }),
});

export const ModulePopover: React.FC<ModulePopoverProps> = ({
  body,
  placement,
  rootClose = true,
  title,
  trigger = `hover`,
}) =>
  <OverlayTrigger
    trigger={trigger}
    placement={placement}
    rootClose={rootClose}
    overlay={
      <Popover id="popover-basic" style={{ width: `34em` }}>
        <label
          htmlFor="overlay_help_text"
          id="lbl_overlay_help_text"
          className="sr-only"
        >
          {body}
        </label>
        <div
          id="overlay_help_text"
          data-testid="overlay_help_text"
          aria-labelledby="lbl_overlay_help_text"
          aria-live="assertive"
          role="status"
        >
          <PopoverHeading as="h3">{title}</PopoverHeading>
          <Popover.Content
            style={{
              fontFamily: `Helvetica Neue, Helvetica, Arial, sans-serif`,
              fontSize: `1rem`,
              padding: `9px 14px`,
            }}
          >
            <p
              aria-live="assertive"
              role="status"
              className="mb-0"
              dangerouslySetInnerHTML={createMarkupHelpText(body)}
            />
          </Popover.Content>
        </div>
      </Popover>
    }
  >
    {({ ref, ...triggerHandler }) =>
      <Button
        variant="custom"
        style={{
          alignItems: `center`,
          borderRadius: `50%`,
          display: `flex`,
          height: `1.75em`,
          justifyContent: `center`,
          width: `1.75em`,
        }}
        type="button"
        data-testid="helpButton"
        ref={ref}
        {...triggerHandler}
      >
        ?
      </Button>}
  </OverlayTrigger>;
