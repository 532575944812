import { AxiosResponse } from 'axios';
import {
  IAudited,
  LoginDTO,
  PasswordResetDTO,
  PasswordResetRequestDTO,
  UserDTO,
} from 'recertify';
import Axios from '../utils/http.config';

const BASE_URL = `/auth`;

export class AuthService {
  public static login(data: LoginDTO): Promise<AxiosResponse> {
    return Axios.post(`${BASE_URL}/login`, data);
  }

  public static logout(): Promise<AxiosResponse> {
    return Axios.post(`${BASE_URL}/logout`, {});
  }

  public static reset(data: PasswordResetDTO): Promise<string> {
    return Axios.post(`${BASE_URL}/reset/password`, data);
  }

  public static checkResetToken(otk: string): Promise<IAudited<UserDTO>> {
    return Axios.get(`${BASE_URL}/reset/password`, {
      params: { otk },
    });
  }

  public static requestPasswordResetEmail(data: PasswordResetRequestDTO): Promise<AxiosResponse> {
    return Axios.post(`${BASE_URL}/request/password`, data);
  }

  public static requestUsernameEmail(data: PasswordResetRequestDTO): Promise<AxiosResponse> {
    return Axios.post(`${BASE_URL}/request/username`, data);
  }

}
