import * as colors from 'styles/colors';
import { uniqueId } from 'lodash';
import { ErrorResponse } from '../utils/http.config';

export const FullPageErrorFallback: React.FC<{ errors: Array<ErrorResponse | undefined>, height?: string }> =
 ({ errors, height = `100%` }) =>
   <div
     role="alert"
     style={{
       alignItems: `center`,
       color: colors.danger,
       display: `flex`,
       flexDirection: `column`,
       height,
       justifyContent: `center`,
     }}
   >
     <p>Uh oh... There's a problem. Try refreshing the app.</p>
     { errors?.map((e) => e && <pre
       key={uniqueId()}
       style={{
         textAlign: `center`,
         whiteSpace: `pre-wrap`,
       }}>{e.response?.status} - {
         e.response?.data?.message || e.response?.data
       }</pre>) }
   </div>;
