import React from 'react';
import { Column, Table } from '@tanstack/react-table';
import { TextEntryColumnFilter } from './TextEntryColumnFilter';
import { SelectColumnFilter } from './SelectColumnFilter';
import { MultiSelectColumnFilter } from './MultiSelectColumnFilter';

export const ColumnFilter: React.FC<{ column: Column<any, unknown>, table: Table<any> }> = ({ column }) => {
  switch (column.columnDef.filterType) {
    case `text`:
      return <TextEntryColumnFilter column={column} />;
    case `select`:
      return <SelectColumnFilter column={column} />;
    case `multiselect`:
      return <MultiSelectColumnFilter column={column} />;
    default:
      return null;
  }
};
