import axios, { AxiosError } from 'axios';
import Cookies from 'universal-cookie';

interface ApiError {
  error: string;
  message: string;
  statusCode: number;
}

export type ErrorResponse = AxiosError<ApiError>;

const Axios = axios.create({
  baseURL: `/api`,
});

Axios.interceptors.response.use(res => res, (err: ErrorResponse) => {
  if (err?.response?.data?.message === `Invalid or no session`) {
    const cookies = new Cookies();
    cookies.remove(`spa_token`);
    window.location.href = `/login`;
  }

  // eslint-disable-next-line no-console
  console.error(err, err?.response);

  return Promise.reject(err);
});

export default Axios;
