import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import ReactDOM from 'react-dom';
import { AppProviders } from 'contexts/AppProviders';
import React from 'react';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <AppProviders>
    <App />
  </AppProviders>,
  document.getElementById(`root`),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
