import {
  ExamReportDTO,
  ExamSectionQuestionsReportDTO,
  ExamTypeReportDTO,
  ExamTypeSectionQuestionsReportDTO,
  ExamTypeSectionReportDTO,
  FilterParamsDTO,
  IAudited,
  IndividualReportDTO,
  SearchUserResultsDTO,
  UserResultsDTO,
} from 'recertify';
import Axios from '../utils/http.config';

const BASE_URL = `/admin/report`;

export class ReportService {
  public static getExamReports(params: FilterParamsDTO): Promise<ExamReportDTO[]> {
    return Axios.get<{ data: {exams: ExamReportDTO[] }}>(`${BASE_URL}/exam`, {
      params,
    })
      .then(response => response.data.data.exams);
  }

  public static getExamSectionReportById(id: number, params: FilterParamsDTO): Promise<ExamTypeSectionReportDTO[]> {
    return Axios.get<{ data: {sections: ExamTypeSectionReportDTO[] } }>(`${BASE_URL}/exam/${id}/section`, { params })
      .then(response => {
        const { sections } = response.data.data;
        return sections; });
  }

  public static getExamTypeReports(params: FilterParamsDTO): Promise<ExamTypeReportDTO[]> {
    return Axios.get<{ data: {examTypes: ExamTypeReportDTO[] }}>(`${BASE_URL}/examtype`, {
      params,
    })
      .then(response => response.data.data.examTypes);
  }

  public static getIndividualReport(examId: number): Promise<IndividualReportDTO> {
    return Axios.get<{ data: IAudited<IndividualReportDTO> }>(`${BASE_URL}/review/${examId}`)
      .then(response => response.data.data);
  }

  public static getResults(params: SearchUserResultsDTO): Promise<UserResultsDTO[]> {
    return Axios.get<{ data: {sittings: UserResultsDTO[] }}>(`${BASE_URL}/results`, {
      params: {
        ...params,
      },
    }).then(response => response.data.data.sittings);
  }

  public static getExamSectionQuestionsReportById(id: number, params: FilterParamsDTO, section_id: number):
  Promise<ExamSectionQuestionsReportDTO[]> {
    return Axios.get<{
      data: {questions: ExamSectionQuestionsReportDTO[] }; }>(`${BASE_URL}/exam/${id}/section/${section_id}`, {
      params,
    })
      .then(response => response.data.data.questions);
  }

  public static getExamTypeSectionReport(id: number, params: FilterParamsDTO): Promise<ExamTypeSectionReportDTO[]> {
    return Axios.get<{ data: {sections: ExamTypeSectionReportDTO[] } }>(`${BASE_URL}/examType/${id}/section`, {
      params,
    }).then(response => response.data.data.sections);
  }

  public static getExamTypeSectionQuestionsReportById(
    id: number,
    params: FilterParamsDTO,
    section_id: number,
  ):
    Promise<ExamTypeSectionQuestionsReportDTO[]> {
    return Axios.get<{
      data: {questions: ExamTypeSectionQuestionsReportDTO[] }; }>(`${BASE_URL}/examType/${id}/section/${section_id}`, {
      params,
    })
      .then(response => response.data.data.questions);
  }
}
