import React, { createContext, useContext, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

interface ConfirmProps {
  title: string;
  message?: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  stackedModal?: boolean;
  onConfirm: () => void;
}

interface IConfirmationContext { createConfirmation: (confirmation: ConfirmProps) => void }

const ConfirmationContext = createContext<IConfirmationContext | null>(null);

export const useConfirmation = () => {
  const ctx = useContext(ConfirmationContext);
  if (!ctx) { throw new Error(`useConfirmation must be used within a ConfirmationProvider`); }
  return ctx;
};

export const ConfirmationProvider: React.FC = ({ children }) => {
  const [ show, setShow ] = useState(false);
  const [ confirmation, setConfirmation ] = useState<ConfirmProps>();

  const createConfirmation = (props: ConfirmProps) => {
    setConfirmation(props);
    setShow(true);
  };

  return <ConfirmationContext.Provider value={{ createConfirmation }}>
    {children}
    {confirmation &&
      <Modal
        visible={show}
        onClose={() => setShow(false)}
        className={confirmation.stackedModal ? `stacked-modal` : ``}
      >
        <Modal.Header closeButton>
          <Modal.Title>{confirmation.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-dark" data-testid="confirmationModal">
          {confirmation.message || `Are you sure?`}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            data-testid="cancelButton"
            className="px-4 font-weight-bold float-end"
            onClick={() => setShow(false)}
          >
            {confirmation.cancelText || `Cancel`}
          </Button>
          <Button
            variant="danger"
            data-testid="confirmButton"
            className="px-4 font-weight-bold float-end"
            onClick={() => {
              confirmation.onConfirm();
              setShow(false);
            }}
          >
            {confirmation.confirmText || `Confirm`}
          </Button>
        </Modal.Footer>
      </Modal>}
  </ConfirmationContext.Provider>;
};
