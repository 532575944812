import { useEffect, useState } from 'react';

export interface AnnouncementProps {
  active: boolean;
  show_on_banner: boolean;
  show_on_login: boolean;
  class: string;
  condition?: string;
  message: string;
}

const env = process.env.REACT_APP_ENV || process.env.NODE_ENV;
const appName = process.env.REACT_APP_NAME;
const prodUrl = process.env.REACT_APP_PROD_URL;

const replaceText = (message: string) => message
  .replace(/(NODE_ENV)+/g, env)
  .replace(/(APPLICATION_NAME)+/g, appName ? appName : `undefined`)
  .replace(/(PRODUCTION_URL)+/g, prodUrl ? prodUrl : `undefined`);

export const useSystemAnnouncement = (location: 'banner' | 'login') => {
  const [ announcements, setAnnouncements ] = useState<AnnouncementProps[] | []>([]);

  useEffect(() => {
    const locationProp = location === `banner` ? `show_on_banner` : `show_on_login`;
    const getData = async () => {
      const data = await fetch(`/files/announcements.json`)
        .then((response) => response.json() as Promise<AnnouncementProps[]>);
      const processedData = data
        .filter(i => i.active)
        .filter(i => i[locationProp])
        // eslint-disable-next-line no-eval
        .filter(i => eval(i?.condition || `true`))
        .map(i => ({
          ...i,
          message: replaceText(i.message),
        }));
      setAnnouncements(processedData);
    };
    void getData();
  }, [ location ]);

  return announcements;
};
