import React, { KeyboardEvent } from 'react';

interface DivButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent> | KeyboardEvent<HTMLDivElement>) => void;
}

export const getClickableProps = (
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent> | KeyboardEvent<HTMLDivElement>) => void,
) => ({
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (onClick) {
      onClick(e);
    }
  },
  onKeyUp: (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === `Enter` || e.key === `Spacebar` || e.key === ` `) {
      onClick(e);
    }
  },
  role: `button`,
  tabIndex: 0,
});

const DivButton = ({ children, onClick, ...extraProps }: DivButtonProps) =>
  <div
    {...getClickableProps(onClick)}
    {...extraProps}
  >
    {children}
  </div>;

export default DivButton;
