import React, { useContext, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { useAsyncEffect } from '@react-hook/async';
import { AsyncPageWrapper } from 'components';
import { UserService } from 'services/UserService';
import { useNavigate } from 'react-router-dom';
import { IAudited, LoginDTO, PermissionDTO, ScaryPermissionDTO, UserDTO } from 'recertify';
import { AuthService } from 'services';

export interface IAuthContext {
  user: UserDTO | null;
  permissions: PermissionDTO[];
  scaryPermissions: ScaryPermissionDTO[];
  login: (data: LoginDTO, referrer?: string) => Promise<void>;
  logout: () => Promise<void>;
}

const checkIsAuthenticated = () => {
  const cookies = new Cookies();
  const spa_token: string = cookies.get(`spa_token`);

  if (spa_token) {
    return !!JSON.parse(spa_token);
  }

  return false;
};

const bootstrapAppData = async (): Promise<IAudited<UserDTO> | null> => {
  if (checkIsAuthenticated()) {
    return UserService.getInfo();
  }

  return null;
};

export const AuthContext = React.createContext<IAuthContext | undefined>(undefined);
AuthContext.displayName = `AuthContext`;

export const AuthProvider: React.FC = (props: any) => {
  const [ isAuthenticated, setIsAuthenticated ] = useState(() => checkIsAuthenticated());
  const { error, status, value: user } = useAsyncEffect(() => bootstrapAppData(), [ isAuthenticated ]);
  const [ permissions, setPermissions ] = useState<PermissionDTO[]>();
  const [ scaryPermissions, setScaryPermissions ] = useState<ScaryPermissionDTO[]>();
  const [ info, setInfo ] = useState<UserDTO>();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setPermissions(user.role?.permissions);
      setScaryPermissions(user.role?.scaryPermissions);

      setInfo(user);
    }
  }, [ user ]);

  const login = React.useCallback((form: LoginDTO, referrer?: string) =>
    AuthService
      .login(form)
      .then(() => {
        setIsAuthenticated(true);

        if (referrer) {
          navigate(referrer);
        }
      }),
  [ navigate ]);

  const logout = React.useCallback(() => {
    void AuthService.logout().then(() => setIsAuthenticated(false));
  }, [ ]);

  const value = React.useMemo(() =>
    // eslint-disable-next-line sort-keys
    ({ scaryPermissions, login, logout, user, info, setInfo, permissions }),
  [ scaryPermissions, login, logout, user, info, setInfo, permissions ]);

  return (
    <AsyncPageWrapper status={status} error={error} height="100vh">
      <AuthContext.Provider value={value} {...props} />
    </AsyncPageWrapper>
  );
};

export const useAuth = (): IAuthContext => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }

  return context;
};
