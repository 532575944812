import React, { BaseSyntheticEvent } from 'react';
import { FormControl } from 'react-bootstrap';
import { Column } from '@tanstack/react-table';

export const TextEntryColumnFilter: React.FC<{ column: Column<any, unknown> }> = ({ column }) => {
  const filterValue = column.getFilterValue();

  return <FormControl
    data-testid={`${column.id}-filter`}
    placeholder="Search"
    value={filterValue === undefined ? `` : filterValue as string}
    onChange={(e: BaseSyntheticEvent) => {
      column.setFilterValue(e.target.value === `` ? undefined : e.target.value);
    }}
    onClick={(e: BaseSyntheticEvent) => e.stopPropagation()}
  />;
};
