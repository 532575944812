import { GetRolesDTO, RoleDTO } from 'recertify';
import Axios from '../utils/http.config';

const BASE_URL = `/role`;

export class RoleService {
  public static get(params: GetRolesDTO = {}): Promise<RoleDTO[]> {
    return Axios.get<{ roles: RoleDTO[] }>(`${BASE_URL}`, {
      params,
    })
      .then(response => response.data.roles);
  }

  public static getByAgencyId(agencyId: number): Promise<RoleDTO[]> {
    return Axios.get<{data: RoleDTO[]}>(`${BASE_URL}/agency/${agencyId}`, { })
      .then(response => response.data.data);
  }

  public static getAllRoleForAgency(id: number): Promise<RoleDTO[]> {
    return Axios.get<{ data: RoleDTO[] }>(`${BASE_URL}/agency/${id}`)
      .then(response => response.data.data);
  }
}
