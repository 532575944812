import React, { useMemo } from 'react';
import { Column } from '@tanstack/react-table';
import Select from 'react-select';

export const MultiSelectColumnFilter: React.FC<{ column: Column<any, unknown> }> = ({ column }) => {
  const options = useMemo(() =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    Array.from(column.getFacetedUniqueValues().keys()).sort(),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [ column.getFacetedUniqueValues() ]);

  // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
  return <div onClick={e => e.stopPropagation()}>
    <Select
      data-testid={`${column.id}-filter`}
      isMulti
      onChange={(e) => column.setFilterValue(e.length ? e : undefined)}
      options={options}
      inputId="tags"
      placeholder="Search"
      classNamePrefix="react-select"
    />
  </div>;
};
