import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

interface ISidebarContext {
  sidebarUnfoldable: boolean;
  setSidebarUnfoldable: Dispatch<SetStateAction<boolean>>;
  sidebarShow: boolean;
  setSidebarShow: Dispatch<SetStateAction<boolean>>;
}

const SidebarContext = createContext<ISidebarContext | undefined>(undefined);
SidebarContext.displayName = `SidebarContext`;

export const SidebarProvider: React.FC = ({ children }) => {
  const [ sidebarShow, setSidebarShow ] = useState(true);
  const [ sidebarUnfoldable, setSidebarUnfoldable ] = useState(false);

  return <SidebarContext.Provider value={{ setSidebarShow, setSidebarUnfoldable, sidebarShow, sidebarUnfoldable }}>
    {children}
  </SidebarContext.Provider>;
};

export const useSidebar = (): ISidebarContext => {
  const context = useContext(SidebarContext);
  if (context === undefined) {
    throw new Error(`useSidebar must be used within a SidebarProvider`);
  }
  return context;
};
