import React from 'react';
import { ReactNotificationOptions, store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import '../scss/notification.scss';

/**
 * notification types:
 *  success
 *  danger
 *  info
 *  default
 *  warning
 */

export const triggerNotification = (
  message: string,
  title: string,
  variant: 'success' | 'danger' | 'info' | 'default' | 'warning',
) => {
  const notification: ReactNotificationOptions = {
    animationIn: [ `animated`, `fadeIn` ],
    animationOut: [ `animated`, `fadeOut` ],
    container: `bottom-left`,
    dismiss: {
      duration: 3500,
      onScreen: true,
    },
    insert: `bottom`,
    message: <div aria-live={variant === `success` ? `polite` : `assertive`}>{ message }</div>,
    title,
    type: variant,
    width: 275,
  };

  store.addNotification(notification);
};

export const handleSuccess = (message: string) => {
  triggerNotification(
    message,
    `Success`,
    `success`,
  );
};

export const handleError = (err: Error) => {
  if (err.message.includes(`Internal Server Error -`)) {
    err.message = err.message.replace(`Internal Server Error -`, ``);
  }
  const message = err.message || `An unknown error has occurred`;
  triggerNotification(
    message,
    `Error`,
    `danger`,
  );
};

export const handleValidationError = (err: Error) => {
  const message = err.message || `An unknown error has occurred`;
  triggerNotification(
    message,
    `Warning`,
    `warning`,
  );
};
