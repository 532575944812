import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import { AuthProvider } from './AuthContext';
import { AbilityProvider } from './AbilityContext';
import { SidebarProvider } from './SidebarContext';
import { ConfirmationProvider } from './ConfirmationContext';

export const AppProviders: React.FC = ({ children }) =>
  <React.StrictMode>
    <ReactNotification />
    <Router>
      <AuthProvider>
        <AbilityProvider>
          <ConfirmationProvider>
            <SidebarProvider>
              {children}
            </SidebarProvider>
          </ConfirmationProvider>
        </AbilityProvider>
      </AuthProvider>
    </Router>
  </React.StrictMode>;
