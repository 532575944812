import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ButtonProps } from 'react-bootstrap/Button';
import { Placement } from 'react-bootstrap/esm/Overlay';

interface Props extends ButtonProps {
  tooltipText?: string;
  tooltipPlacement?: Placement;
  enableTooltip?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

export const ButtonWithTooltip: React.FC<Props> = ({
  disabled,
  enableTooltip,
  onClick,
  tooltipPlacement,
  tooltipText,
  ...other
}) => {
  const adjustedButtonProps = {
    component: disabled ? `div` : undefined,
    disabled,
    onClick: disabled ? undefined : onClick,
  };

  return enableTooltip ?
    <OverlayTrigger
      placement={tooltipPlacement}
      overlay={(<Tooltip id={`tooltip-${tooltipText ?? ``}`}>{tooltipText}</Tooltip>)}
    >
      <Button {...other} {...adjustedButtonProps} style={{
        cursor: disabled ? `not-allowed` : ``,
        pointerEvents: `auto`,
      }} />
    </OverlayTrigger> :
    <Button {...other} disabled={disabled} onClick={onClick} />;
};
