import React, { createContext, useContext, useEffect, useState } from 'react';
import { Ability, AnyAbility, PureAbility } from '@casl/ability';
import { createContextualCan } from '@casl/react';
import arrify from 'arrify';
import { useAuth } from './AuthContext';

const AbilityContext = createContext<AnyAbility>(new PureAbility());
AbilityContext.displayName = `AbilityContext`;

export const AbilityProvider: React.FC = ({ children }) => {
  const [ ability, setAbility ] = useState<Ability>(new Ability());
  const { permissions, scaryPermissions } = useAuth();

  useEffect(() => {
    setAbility(new Ability([ ...arrify(permissions), ...arrify(scaryPermissions) ]));
  }, [ scaryPermissions, permissions ]);

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
};

export const Can = createContextualCan(AbilityContext.Consumer);

export const useAbility = (): AnyAbility => {
  const context = useContext(AbilityContext);
  if (context === undefined) {
    throw new Error(`useAbility must be used within a AbilityProvider`);
  }

  return context;
};
