import AsyncSelect from 'react-select/async';
import { DebounceInput } from 'react-debounce-input';
import Select from 'react-select';
import { Card, Col, Row } from 'react-bootstrap';
import React from 'react';

export type FilterSchema = Array<{
  label: string;
  name: string;
  type: `select` | `text` | `date` | `async-select` | `checkbox`;
  options?: Array<{ label: string, value: string | number }>;
  onChange: (value: any, name: string) => void;
  isDisabled?: boolean;
  value?: any;
}>;
export const Filter: React.FC<{
  className?: string;
  debounce?: number;
  schema: FilterSchema;
}> = ({ debounce = 300, schema }) =>
  <Card>
    <Card.Body >
      <form className="container">
        <Row>
          {
            schema.map(node =>
              <React.Fragment key={node.label}>
                <Col>
                  <label
                    htmlFor={`${node.name}`}
                    id={`lbl-${node.name}`}
                    data-testid={`${node.name}`}
                    className="form-group w-100">
                    {node.label}
                    {
                      node.type === `select` ?
                        <Select
                          classNamePrefix="react-select"
                          id={`${node.name}`}
                          aria-labelledby={`lbl-${node.name}`}
                          {...node}
                          onChange={(value: any) => node.onChange(value?.value, node.name)}
                          isDisabled={node.isDisabled}
                          isClearable
                        /> :
                        node.type === `async-select` ?
                          <AsyncSelect
                            backspaceRemovesValue
                            classNamePrefix="react-select"
                            id={`${node.name}`}
                            aria-labelledby={`lbl-${node.name}`}
                            {...node}
                            onChange={(value: any) => node.onChange(value?.value, node.name)}
                            isDisabled={node.isDisabled}
                            isClearable
                          /> :
                          node.type === `checkbox` ?
                            <div className="d-flex justify-content-start">
                              <input
                                id={`${node.name}`}
                                data-testid={`${node.name}`}
                                type="checkbox"
                                onChange={(e) => node.onChange(e.target.checked, node.name)}
                                defaultChecked={node.value}
                              />
                            </div> :
                            <div className="d-flex justify-content-start">
                              <DebounceInput
                                debounceTimeout={debounce}
                                className="form-control"
                                id={`${node.name}`}
                                data-testid={`${node.name}`}
                                {...node}
                                onChange={(e) => node.onChange(e.target?.value.trim(), node.name)}
                                aria-describedby={node.type === `date` ? `dateFormat` : undefined}
                              />
                              {
                                !!(node.type === `date`) &&
                                  <span id="dateFormat" className="sr-only">mm/dd/yyyy</span>
                              }
                            </div>
                    }
                  </label>
                </Col>
              </React.Fragment>)
          }
        </Row>
      </form>
    </Card.Body>
  </Card>;
