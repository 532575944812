import { AxiosResponse } from 'axios';
import {
  AgencyDTO,
  AvatarUpdateDTO,
  IAudited,
  RetakeLimitDTO,
  RoleDTO,
  SearchUsersDTO,
  UserDTO,
} from 'recertify';
import Axios from '../utils/http.config';

const BASE_URL = `/user`;

interface IUserDuplicatesDTO extends UserDTO {
  duplicates: UserDTO[];

}
export class UserService {
  public static getInfo(): Promise<IAudited<UserDTO>> {
    return Axios.get<{ data: IAudited<UserDTO> }>(`${BASE_URL}/info`)
      .then(response => response.data.data);
  }

  public static search(params: SearchUsersDTO = {}): Promise<Array<IAudited<UserDTO>>> {
    return Axios.get<{ data: Array<IAudited<UserDTO>> }>(`${BASE_URL}/list`, {
      params: {
        ...params,
      },
    })
      .then(response => response.data.data);
  }

  public static getById(id: number): Promise<IAudited<UserDTO>> {
    return Axios.get<{ data: IAudited<UserDTO> }>(`${BASE_URL}/${id}/info`)
      .then(response => response.data.data);
  }

  public static getOrganization(): Promise<IAudited<AgencyDTO[]>> {
    return Axios.get<{ data: IAudited<AgencyDTO[]> }>(`${BASE_URL}/agency/organization`)
      .then(response => response.data.data);
  }

  public static createUser(user: UserDTO): Promise<AxiosResponse> {
    const roleString = JSON.stringify({ agency_id: user?.agency?.id, role_id: user?.role?.id });

    return Axios.post(`${BASE_URL}/register`, {
      email: user.email,
      firstName: user.first_name,
      lastName: user.last_name,
      role: roleString,
    });
  }

  public static update(id: number, user: UserDTO): Promise<IAudited<UserDTO>> {
    return Axios.put<{ user: IAudited<UserDTO> }>(`${BASE_URL}/${id}/update`, user)
      .then(response => response.data.user);
  }

  public static delete(id: number): Promise<AxiosResponse> {
    return Axios.delete(`${BASE_URL}/${id}`);
  }

  public static disable(id: number): Promise<AxiosResponse> {
    return Axios.put(`${BASE_URL}/${id}/disable`);
  }

  public static restore(id: number): Promise<AxiosResponse> {
    return Axios.put(`${BASE_URL}/${id}/restore`);
  }

  public static bulkCreate(users: UserDTO[], role: RoleDTO): Promise<AxiosResponse> {
    return Axios.post(`${BASE_URL}/add/bulk`, { role, users });
  }

  public static checkForDuplicates(user: Partial<UserDTO>): Promise<UserDTO[]> {
    return Axios.post<{data: UserDTO[]}>(`${BASE_URL}/user-duplicates-check`,
      { user }).then((response) => response.data.data);
  }

  public static checkDuplicatesFromCsv(users: Array<Partial<UserDTO>>): Promise<IUserDuplicatesDTO[]> {
    return Axios.post< {data: {success: IUserDuplicatesDTO[]}} >(`${BASE_URL}/bulk-duplicates`,
      { users }).then((response) => response.data.data.success);
  }

  public static getParentAgencyCount(): Promise<number> {
    return Axios.get<{data: Promise<number> }>(`${BASE_URL}/parentAgencyCount`)
      .then(response => response.data.data);
  }

  public static getToken(): string {
    const _user = localStorage.getItem(`certtest.user`);

    if (_user) {
      const user = JSON.parse(_user) as { token?: string };
      return user.token || ``;
    }

    return ``;
  }

  public static getRetakeLimits(infoSets: RetakeLimitDTO[] = []): Promise<RetakeLimitDTO[]> {
    return Axios.post<{ data: RetakeLimitDTO[]}>(`${BASE_URL}/getRetakeLimits`, { infoSets })
      .then(response => response.data.data);
  }

  public static getChildAgencies(): Promise<AgencyDTO[]> {
    return Axios.get<{ data: AgencyDTO[] }>(`${BASE_URL}/agency/childAgencies`)
      .then(response => response.data.data);
  }

  public static updateUserAvatar(form: FormData): Promise<AvatarUpdateDTO> {
    return Axios.post<{ data: AvatarUpdateDTO }>(`${BASE_URL}/avatar`, form)
      .then(response => response.data.data);
  }
}
