import { keyframes } from '@emotion/react';

import styled from '@emotion/styled/macro';
import { FaSpinner } from 'react-icons/fa';

const spin = keyframes({
  '0%': { transform: `rotate(0deg)` },
  '100%': { transform: `rotate(360deg)` },
});

export const Spinner = styled(FaSpinner)({
  animation: `${spin} 1s linear infinite`,
});
Spinner.defaultProps = {
  'aria-label': `loading`,
};

export const FullPageSpinner: React.FC<{ height?: string }> = ({ height = `100%` }) =>
  <div data-testid="loadingSpinner"
    style={{
      alignItems: `center`,
      display: `flex`,
      flexDirection: `column`,
      fontSize: `4em`,
      height,
      justifyContent: `center`,
    }}
  >
    <Spinner />
  </div>;
